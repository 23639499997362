<template>
    <v-list-group
        v-if="drawer.type === MenuType.GROUP"
        collapse-icon="mdi-chevron-down"
        expand-icon="mdi-chevron-right"
        color="primary"
    >
      <template #activator="{ props }">
        <v-list-item
            v-bind="props"
            :prepend-icon="drawer.icon ?? ''"
            :title="t('menu.' + drawer.id)"
            class="group-child-menu"
        ></v-list-item>
      </template>
      <slot />
    </v-list-group>

    <v-list-item
        v-else
        :value="drawer.url"
        :link="drawer.type === MenuType.PAGE"
        :target="drawer.type === MenuType.NEW_TAB ? '_blank' : undefined"
        :prepend-icon="drawer.icon ?? ''"
        :title="t('menu.' + drawer.id)"
        color="rgb(var(--v-theme-primary))"
        @click="movePage(drawer)"
        @contextmenu.prevent="handleContextMenu"
    />
</template>

<script setup lang="ts">
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { routerPush } from "@/utils/commands";

const { t } = useI18n();

const emit = defineEmits(['showContextMenu']);

const props = defineProps<{
  drawer: Drawer;
}>();

const route = useRoute();
function handleContextMenu(event: MouseEvent): void {
  emit('showContextMenu', event, props.drawer); // 상위 컴포넌트에 이벤트 전달
}
function movePage(item: Drawer): void {
  if (!item.url || item.url === route.fullPath) {
    return;
  }
  routerPush(item.url);
  // if (ENVIRONMENT === "local" && item.type !== "W") {
  // } else {
  //   // popupWindow(item);
  // }
}

// function popupWindow(item: Drawer): void {
//   const newWindow = window.open(item.url, "_blank");
//   if (newWindow) {
//     newWindow.onload = function () {
//       const title = `Admin-${t("menu." + item.id)}`;
//       newWindow.document.title = title;
//       newWindow.window.sessionStorage.setItem("appTitle", title);
//     };
//   }
// }

</script>
<style lang="scss" scoped>
//.v-list-group{
//  border-bottom: 2px solid lightgray;
//}
.v-list-item{
  border-bottom: 1px solid lightgray;
  .v-list-item-title{
    font-size: 15px;
  }
}
</style>
